import './index.css'
import { makeStyles } from '@mui/styles'
import { ReactComponent as Logo } from './figgili.svg'

const useStyles = makeStyles( theme => ({
  "@global": {
    html: {
      margin: 0,
      padding: 0,
      fontSize: "clamp(13px, 1vw, 30px)",
    },
    body: {
      backgroundColor: "#1d2837",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    main: {
      width: "25vw",
    },
  },
  textBox: {
    position: "absolute",
    color: "#b17f5c",
    fontFamily: "'Lobster', cursive",
    fontSize: "2rem",
    padding: "2rem",
    bottom: 0,
    left: 0,
  },
}))

const App = () => {

  const classes = useStyles()

  return (
    <>
      <main>
        <Logo />
      </main>
      <div className={classes.textBox}>The coder is coding, the monkey is coming ;)</div>
    </>
  );
}

export default App;
